import React from "react";

import Breadcrumb from "../components/common/breadcrumb";
import Seo from "../components/common/SEO";
import MainLayout from "../layouts/MainLayout";
import "./general.scss";

const General = ({ pageContext }) => {
  const { title, content, seoMetaData } = pageContext;
  return (
    <MainLayout>
      <Seo title={title} page={{ title, seoMetaData }} />
      {/* <PageHeader title={title} /> */}
      <Breadcrumb title={title} />

      <section className="section-b-space">
        <div className="collection-wrapper">
          <div className="container">
            <div className="row">
              <div className="collection-content col">
                <div className="page-main-content">
                  <h2 className="pb-4">{title}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<div>${content}</div>`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};

export default General;
